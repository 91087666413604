<template>
  <div class="aboutus">
    <div class="aboutus-content">
      <div class="map-warp">
        
      </div>
      <a-descriptions title="联系我们">
        <a-descriptions-item label="我们是">
          重庆市巴蜀小学校
        </a-descriptions-item>
        <a-descriptions-item label="地址">
          重庆市渝中区巴教村15号
        </a-descriptions-item>
        <a-descriptions-item label="邮编">
          400013
        </a-descriptions-item>
      </a-descriptions>
      <a-divider />
      <a-descriptions title="技术交流">
        <a-descriptions-item label="课程产品咨询和意见反馈">
          52575946@qq.com
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.aboutus-content {
    width: 1170Px;
    margin: 0 auto;
    padding-top: 60Px;
}
</style>